// client/src/components/Table.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Table = ({ columns = [], data = [], className = '', onRowClick = () => {}, rowClickable = false }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [filters, setFilters] = useState({});
    const [suggestions, setSuggestions] = useState({});

    const sortedData = React.useMemo(() => {
        let sortableData = [...data];
        if (sortConfig.key !== null) {
            sortableData.sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];
                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const filteredData = React.useMemo(() => {
        return sortedData.filter(row => {
            return columns.every((col, index) => {
                const filterValue = filters[col];
                if (!filterValue) return true;
                const cellValue = Object.values(row)[index];
                return cellValue.toString().toLowerCase().includes(filterValue.toLowerCase());
            });
        });
    }, [sortedData, filters, columns]);

    const handleSort = (column) => {
        let direction = 'ascending';
        if (sortConfig.key === column && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: column, direction });
    };

    const getSortIndicator = (column) => {
        if (sortConfig.key === column) {
            return sortConfig.direction === 'ascending' ? '▲' : '▼';
        }
        return null;
    };

    const handleFilterChange = (column, value) => {
        setFilters({
            ...filters,
            [column]: value,
        });

        // Show suggestions if input is longer than 3 characters
        if (value.length > 3) {
            const columnData = data.map(row => Object.values(row)[columns.indexOf(column)]);
            const uniqueSuggestions = [...new Set(columnData.filter(item => item.toString().toLowerCase().includes(value.toLowerCase())))];
            setSuggestions({
                ...suggestions,
                [column]: uniqueSuggestions.slice(0, 5), // Limit to 5 suggestions
            });
        } else {
            setSuggestions({
                ...suggestions,
                [column]: [],
            });
        }
    };

    const handleSuggestionClick = (column, suggestion) => {
        setFilters({
            ...filters,
            [column]: suggestion,
        });
        setSuggestions({
            ...suggestions,
            [column]: [],
        });
    };

    return (
        <div className={`overflow-x-auto ${className}`}>
            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
                <thead>
                <tr>
                    {columns.map((col) => (
                        <th
                            key={col}
                            onClick={() => handleSort(col)}
                            className="px-4 py-3 text-left bg-gray-50 border-b text-sm font-medium text-gray-700 cursor-pointer"
                        >
                            {col} {getSortIndicator(col)}
                        </th>
                    ))}
                </tr>
                <tr>
                    {columns.map((col) => (
                        <th key={`${col}-filter`} className="px-4 py-3 bg-gray-50 border-b relative">
                            <input
                                type="text"
                                placeholder={`Filter ${col}`}
                                value={filters[col] || ''}
                                onChange={(e) => handleFilterChange(col, e.target.value)}
                                className="w-full px-2 py-1 text-sm border rounded-md"
                            />
                            {suggestions[col] && suggestions[col].length > 0 && (
                                <ul className="absolute left-0 w-full bg-white border border-gray-200 rounded-md shadow-lg z-10 max-h-40 overflow-y-auto">
                                    {suggestions[col].map((suggestion, index) => (
                                        <li
                                            key={index}
                                            onClick={() => handleSuggestionClick(col, suggestion)}
                                            className="px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                                        >
                                            {suggestion}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {filteredData.map((row, index) => (
                    <tr
                        key={index}
                        onClick={rowClickable ? () => onRowClick(row) : null}
                        className={`transition-all duration-150 ease-in-out ${
                            rowClickable ? 'cursor-pointer hover:bg-gray-100' : ''
                        }`}
                    >
                        {Object.values(row).map((cell, cellIndex) => (
                            <td key={cellIndex} className="px-4 py-3 border-b text-sm text-gray-800">
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string,
    onRowClick: PropTypes.func,
    rowClickable: PropTypes.bool,
};

export default Table;




