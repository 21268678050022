import { useState, useEffect } from 'react';
import api from '../services/api';

export const useFetchData = () => {
    const [categories, setCategories] = useState([]);
    const [units, setUnits] = useState([]);
    const [allIngredients, setAllIngredients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadError, setLoadError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [categoriesResponse, unitsResponse, ingredientsResponse] = await Promise.all([
                    api.get('/categories'),
                    api.get('/units'),
                    api.get('/ingredients'),
                ]);

                setCategories(categoriesResponse.data || []);
                setUnits(unitsResponse.data?.map(unit => ({
                    value: unit._id,
                    label: `${unit.name} (${unit.abbreviation})`,
                })) || []);
                setAllIngredients(ingredientsResponse.data?.map(ingredient => ({
                    value: ingredient._id,
                    label: ingredient.name?.en || Object.values(ingredient.name || {})[0] || 'Unnamed Ingredient',
                })) || []);
            } catch (error) {
                console.error('Failed to load data:', error);
                setLoadError('Failed to load data. Please try again.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    return { categories, units, allIngredients, isLoading, loadError };
};