// client/src/components/AppSettings.js

import React, { useContext, useState, useEffect } from 'react';
import SettingsContext from '../context/SettingsContext';
import api from '../services/api';
import { toast } from 'react-toastify';
import Loader from './Loader';

const AppSettings = () => {
    const { appName, primaryColor, secondaryColor, updateAppName, updatePrimaryColor, updateSecondaryColor, updateCurrency, updateLanguage } = useContext(SettingsContext);
    const [newAppName, setNewAppName] = useState(appName);
    const [newPrimaryColor, setNewPrimaryColor] = useState(primaryColor);
    const [newSecondaryColor, setNewSecondaryColor] = useState(secondaryColor);
    const [newCurrency, setNewCurrency] = useState('USD');
    const [newLanguage, setNewLanguage] = useState('en');
    const [loading, setLoading] = useState(false);
    const [convertPrices, setConvertPrices] = useState(false);

    useEffect(() => {
        const fetchSettings = async () => {
            setLoading(true);
            try {
                const response = await api.get('/settings');
                if (response.data) {
                    const { appName, primaryColor, secondaryColor, currency, language } = response.data;
                    setNewAppName(appName || 'MyApp');
                    setNewPrimaryColor(primaryColor || '#3B82F6');
                    setNewSecondaryColor(secondaryColor || '#6B7280');
                    setNewCurrency(currency || 'USD');
                    setNewLanguage(language || 'en');
                    updateAppName(appName || 'MyApp');
                    updatePrimaryColor(primaryColor || '#3B82F6');
                    updateSecondaryColor(secondaryColor || '#6B7280');
                    updateCurrency(currency || 'USD');
                    updateLanguage(language || 'en');
                } else {
                    toast.error('Failed to load settings: No data available');
                }
                setLoading(false);
            } catch (error) {
                toast.error('Failed to load settings');
                setLoading(false);
            }
        };
        fetchSettings();
    }, [updateAppName, updatePrimaryColor, updateSecondaryColor, updateCurrency, updateLanguage]);

    const handleCurrencyChange = (newCurrency) => {
        const confirmConversion = window.confirm("Do you want to convert the prices to the new currency?");
        setConvertPrices(confirmConversion);
        setNewCurrency(newCurrency);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.put('/settings', {
                appName: newAppName,
                primaryColor: newPrimaryColor,
                secondaryColor: newSecondaryColor,
                currency: newCurrency,
                language: newLanguage,
                convertPrices, // Pass whether to convert prices
            });
            updateAppName(response.data.appName);
            updatePrimaryColor(response.data.primaryColor);
            updateSecondaryColor(response.data.secondaryColor);
            updateCurrency(response.data.currency);
            updateLanguage(response.data.language);
            toast.success('Settings updated successfully');
        } catch (error) {
            toast.error('Failed to save settings');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            {/* App Name Input */}
            <div>
                <label htmlFor="appName" className="block text-sm font-medium text-gray-700 mb-1">
                    App Name
                </label>
                <input
                    type="text"
                    id="appName"
                    value={newAppName}
                    onChange={(e) => setNewAppName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 ease-in-out"
                />
            </div>

            {/* Primary Color Input */}
            <div>
                <label htmlFor="primaryColor" className="block text-sm font-medium text-gray-700 mb-1">
                    Primary Color
                </label>
                <input
                    type="color"
                    id="primaryColor"
                    value={newPrimaryColor}
                    onChange={(e) => setNewPrimaryColor(e.target.value)}
                    className="w-16 h-10 p-0 border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
                />
            </div>

            {/* Secondary Color Input */}
            <div>
                <label htmlFor="secondaryColor" className="block text-sm font-medium text-gray-700 mb-1">
                    Secondary Color
                </label>
                <input
                    type="color"
                    id="secondaryColor"
                    value={newSecondaryColor}
                    onChange={(e) => setNewSecondaryColor(e.target.value)}
                    className="w-16 h-10 p-0 border border-gray-300 rounded-lg cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 ease-in-out"
                />
            </div>

            {/* Currency Input */}
            <div>
                <label htmlFor="currency" className="block text-sm font-medium text-gray-700 mb-1">
                    Currency
                </label>
                <select
                    id="currency"
                    value={newCurrency}
                    onChange={(e) => handleCurrencyChange(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 ease-in-out"
                >
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="JPY">JPY</option>
                    <option value="INR">INR</option>
                    {/* Add more currencies as needed */}
                </select>
            </div>

            {/* Language Input */}
            <div>
                <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-1">
                    Language
                </label>
                <select
                    id="language"
                    value={newLanguage}
                    onChange={(e) => setNewLanguage(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 ease-in-out"
                >
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                    <option value="fr">French</option>
                    <option value="de">German</option>
                    <option value="ta">Tamil</option>
                    {/* Add more languages as needed */}
                </select>
            </div>

            {/* Save Button */}
            <button
                type="submit"
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-200 ease-in-out"
            >
                Save Settings
            </button>
        </form>
    );
};

export default AppSettings;
