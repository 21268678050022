// client/src/components/StockForm.js

import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import Select from 'react-select';
import api from '../services/api';
import { toast } from 'react-toastify';
import AuthContext from '../context/authContext';

const UNIT_CONVERSIONS = {
    weight: { gram: 1, kilogram: 1000, pound: 453.592, ounce: 28.3495 },
    volume: { milliliter: 1, liter: 1000, cup: 240, tablespoon: 15, teaspoon: 5 },
    pieces: { piece: 1 },
};

const convertQuantity = (quantity, fromUnit, toUnit, category) => {
    const conversionFactor = UNIT_CONVERSIONS[category][toUnit] / UNIT_CONVERSIONS[category][fromUnit];
    return quantity * conversionFactor;
};

const StockForm = ({ initialStock = {}, onSave, onCancel }) => {
    const { user } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        ingredient_id: '',
        unit: '',
        quantity_in_stock: '',
        price_per_unit: '',
        supplier_id: '',
        batch_number: '',
        expiry_date: '',
        received_date: '',
        storage_location: '',
        status: 'In Stock',
    });

    const [options, setOptions] = useState({
        ingredients: [],
        suppliers: [],
        storageLocations: [],
        units: [],
    });

    const fetchOptions = useCallback(async () => {
        try {
            const [ingredients, suppliers, locations, units] = await Promise.all([
                api.get('/ingredients'),
                api.get('/suppliers'),
                api.get('/storage-locations'),
                api.get('/units'),
            ]);

            setOptions({
                ingredients: ingredients.data,
                suppliers: suppliers.data,
                storageLocations: locations.data,
                units: units.data,
            });
        } catch (error) {
            toast.error('Failed to load form data');
        }
    }, []);

    useEffect(() => {
        fetchOptions();
    }, [fetchOptions]);

    const handleChange = useCallback((name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
    }, []);

    const handleSelectChange = useCallback((name, selectedOption) => {
        handleChange(name, selectedOption ? selectedOption.value : '');
    }, [handleChange]);

    const handleUnitChange = useCallback((name, selectedOption) => {
        if (!selectedOption) return;

        const newUnit = options.units.find(unit => unit._id === selectedOption.value);
        const oldUnit = options.units.find(unit => unit._id === formData.unit);

        if (!oldUnit || (newUnit && oldUnit.category === newUnit.category)) {
            const newQuantity = oldUnit
                ? convertQuantity(
                    parseFloat(formData.quantity_in_stock) || 0,
                    oldUnit.abbreviation,
                    newUnit.abbreviation,
                    newUnit.category
                )
                : formData.quantity_in_stock;

            setFormData(prev => ({
                ...prev,
                unit: selectedOption.value,
                quantity_in_stock: newQuantity.toString(),
            }));
        } else {
            toast.error('Cannot change unit to a different category.');
        }
    }, [options.units, formData.unit, formData.quantity_in_stock]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const response = await api.post('/stocks', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            onSave(response.data);
            toast.success('Stock saved successfully.');
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            toast.error('Failed to save stock entry');
        }
    };

    const mapOptionsToSelect = useCallback((optionArray, labelKey = 'name') =>
        optionArray.map(option => ({
            value: option._id,
            label: option[labelKey]?.en || option[labelKey],
        })), []);

    const selectOptions = useMemo(() => ({
        ingredients: mapOptionsToSelect(options.ingredients),
        suppliers: mapOptionsToSelect(options.suppliers),
        storageLocations: mapOptionsToSelect(options.storageLocations),
        units: options.units.map(unit => ({
            value: unit._id,
            label: `${unit.name} (${unit.abbreviation})`,
        })),
        status: [
            { value: 'In Stock', label: 'In Stock' },
            { value: 'Out of Stock', label: 'Out of Stock' },
            { value: 'Expired', label: 'Expired' },
            { value: 'Pending', label: 'Pending' },
        ],
    }), [options, mapOptionsToSelect]);

    const renderSelect = useCallback((name, label, options, isRequired = false, onChange = handleSelectChange) => (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <Select
                name={name}
                value={options.find(opt => opt.value === formData[name]) || null}
                onChange={selectedOption => onChange(name, selectedOption)}
                options={options}
                className="react-select-container"
                classNamePrefix="react-select"
                required={isRequired}
            />
        </div>
    ), [formData, handleSelectChange]);

    const renderInput = useCallback((name, label, type = "text", isRequired = false) => (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={(e) => handleChange(name, e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required={isRequired}
            />
        </div>
    ), [formData, handleChange]);

    return (
        <form onSubmit={handleSubmit}>
            {renderSelect('ingredient_id', 'Ingredient', selectOptions.ingredients, true)}
            {renderSelect('unit', 'Unit', selectOptions.units, true, handleUnitChange)}
            {renderInput('quantity_in_stock', 'Quantity', 'number', true)}
            {renderInput('price_per_unit', 'Price per Unit', 'number', true)}
            {renderSelect('supplier_id', 'Supplier', selectOptions.suppliers, true)}
            {renderInput('batch_number', 'Batch Number', 'text', true)}
            {renderInput('expiry_date', 'Expiry Date', 'date')}
            {renderInput('received_date', 'Received Date', 'date', true)}
            {renderSelect('storage_location', 'Storage Location', selectOptions.storageLocations)}
            {renderSelect('status', 'Status', selectOptions.status, true)}

            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={onCancel}
                    className="mr-2 bg-gray-500 text-white px-4 py-2 rounded"
                >
                    Cancel
                </button>
                <button type="submit" className="bg-blue-600 text-white px-4 py-2 rounded">
                    Save
                </button>
            </div>
        </form>
    );
};

export default StockForm;



