// client/src/pages/Dashboard.js

import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SettingsContext from '../context/SettingsContext';
import Loader from '../components/Loader';
import Card from '../components/Card';
import api from '../services/api';
import { toast } from 'react-toastify';

const Dashboard = () => {
    const { primaryColor, secondaryColor, currencySymbol } = useContext(SettingsContext);
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState(null);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const response = await api.get('/dashboard/stats');
                setStats(response.data);
                setLoading(false);
            } catch (error) {
                toast.error('Failed to load dashboard data');
                setLoading(false);
            }
        };
        fetchStats();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-3xl font-bold mb-6" style={{ color: primaryColor }}>Dashboard</h1>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
                <Card
                    title="Total Orders"
                    value={stats ? stats.totalOrders : '...'}
                    backgroundColor="bg-white"
                    textColor={secondaryColor}
                />
                <Card
                    title="Active Tables"
                    value={stats ? stats.activeTables : '...'}
                    backgroundColor="bg-white"
                    textColor={secondaryColor}
                />
                <Card
                    title="Total Revenue"
                    value={stats ? `${currencySymbol} ${stats.totalRevenue}` : '...'}
                    backgroundColor="bg-white"
                    textColor={secondaryColor}
                />
                <Card
                    title="Pending Orders"
                    value={stats ? stats.pendingOrders : '...'}
                    backgroundColor="bg-white"
                    textColor={secondaryColor}
                />
            </div>

            <div className="flex space-x-4 mb-6">
                <Link to="/orders" className="flex-1 text-center bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-200">
                    Manage Orders
                </Link>
                <Link to="/tables" className="flex-1 text-center bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-200">
                    Manage Tables
                </Link>
                <Link to="/menu-items" className="flex-1 text-center bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-200">
                    Manage Menu Items
                </Link>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold mb-4" style={{ color: secondaryColor }}>Recent Activity</h2>
                {stats && stats.recentActivity.length > 0 ? (
                    <ul className="space-y-3">
                        {stats.recentActivity.map((activity, index) => (
                            <li key={index} className="border-b border-gray-200 pb-3">
                                {activity.description}
                                <span className="block text-sm text-gray-500">{activity.timestamp}</span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-gray-500">No recent activity available.</p>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
