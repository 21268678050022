// client/src/pages/Settings.js

import React, { useState, useContext, useEffect } from 'react';
import SettingsContext from '../context/SettingsContext';
import api from '../services/api';
import { toast } from 'react-toastify';
import SettingsTab from '../components/SettingsTab';
import AppSettings from '../components/AppSettings';
import RestaurantSettings from '../components/RestaurantSettings';
import ManagementSettings from '../components/ManagementSettings';
import Loader from '../components/Loader';

const Settings = () => {
    const { updateAppName, updatePrimaryColor, updateSecondaryColor, updateCurrency, updateLanguage } = useContext(SettingsContext);
    const [selectedTab, setSelectedTab] = useState('appSettings');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await api.get('/settings');
                if (response.data) {
                    const { appName, primaryColor, secondaryColor, currency, language } = response.data;
                    updateAppName(appName || 'MyApp');
                    updatePrimaryColor(primaryColor || '#3B82F6');
                    updateSecondaryColor(secondaryColor || '#6B7280');
                    updateCurrency(currency || 'USD');
                    updateLanguage(language || 'en');
                } else {
                    toast.error('Failed to load settings: No data available');
                }
            } catch (error) {
                toast.error('Failed to load settings');
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, [updateAppName, updatePrimaryColor, updateSecondaryColor, updateCurrency, updateLanguage]);

    const tabs = [
        { key: 'appSettings', label: 'App Settings', content: <AppSettings /> },
        { key: 'billing', label: 'Billing', content: <div>Billing Content</div> },
        { key: 'restaurantSettings', label: 'Restaurant Settings', content: <RestaurantSettings /> },
        { key: 'managementSettings', label: 'Management Settings', content: <ManagementSettings /> },
    ];

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="container mx-auto mt-10 settings-container">
            <h1 className="text-2xl font-bold mb-6">Settings</h1>
            <SettingsTab tabs={tabs} selectedTab={selectedTab} onSelectTab={setSelectedTab} />
        </div>
    );
};

export default Settings;
