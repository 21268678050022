import React from 'react';
import AsyncSelect from 'react-select/async';

const IngredientsList = ({ ingredients, allIngredients, units, onChange, error }) => {
    const handleIngredientChange = (index, field, value) => {
        const updatedIngredients = ingredients.map((ingredient, i) =>
            i === index ? { ...ingredient, [field]: value } : ingredient
        );
        onChange(updatedIngredients);
    };

    const handleAddIngredient = () => {
        onChange([...ingredients, { ingredient_id: '', quantity: '', unit: '' }]);
    };

    const handleRemoveIngredient = (index) => {
        onChange(ingredients.filter((_, i) => i !== index));
    };

    const loadIngredients = async (inputValue) => {
        return inputValue.trim() === ''
            ? allIngredients
            : allIngredients.filter(ingredient =>
                ingredient.label.toLowerCase().includes(inputValue.toLowerCase())
            );
    };

    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Ingredients</label>
            {ingredients.map((ingredient, index) => (
                <div key={index} className="flex mb-2">
                    <AsyncSelect
                        cacheOptions
                        defaultOptions={allIngredients}
                        loadOptions={loadIngredients}
                        onChange={(selectedOption) => handleIngredientChange(index, 'ingredient_id', selectedOption ? selectedOption.value : '')}
                        className="mr-2 w-1/3"
                        value={allIngredients.find(ing => ing.value === ingredient.ingredient_id)}
                        placeholder="Select an ingredient"
                    />
                    <input
                        type="number"
                        value={ingredient.quantity}
                        onChange={(e) => handleIngredientChange(index, 'quantity', e.target.value)}
                        className="block w-1/3 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="Quantity"
                    />
                    <select
                        value={ingredient.unit}
                        onChange={(e) => handleIngredientChange(index, 'unit', e.target.value)}
                        className="ml-2 w-1/3 block px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                        <option value="">Select a unit</option>
                        {units.map(unit => (
                            <option key={unit.value} value={unit.value}>
                                {unit.label}
                            </option>
                        ))}
                    </select>
                    <button
                        type="button"
                        onClick={() => handleRemoveIngredient(index)}
                        className="bg-red-600 text-white px-3 rounded hover:bg-red-700 transition duration-200 ml-2"
                    >
                        Remove
                    </button>
                </div>
            ))}
            <button
                type="button"
                onClick={handleAddIngredient}
                className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-200 mt-2"
            >
                Add Ingredient
            </button>
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
    );
};

export default IngredientsList;