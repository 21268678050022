// client/src/components/OrderModal.js

import React, { useState, useEffect, useContext } from 'react';
import Modal from '../components/Modal';
import api from '../services/api';
import AuthContext from '../context/authContext';

const OrderModal = ({ isOpen, onClose, seat }) => {
    const [menuItems, setMenuItems] = useState([]);
    const [menuItem, setMenuItem] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [specialRequests, setSpecialRequests] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [chairIndex, setChairIndex] = useState(0);
    const [chairCount, setChairCount] = useState(0);
    const [pendingOrder, setPendingOrder] = useState(null);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            if (!seat || !seat.table) return;

            try {
                const [menuResponse, orderResponse] = await Promise.all([
                    api.get('/menu-items'),
                    api.get(`/tables/${seat.table._id}/order-status`)
                ]);
                setMenuItems(menuResponse.data);
                if (orderResponse.data && orderResponse.data.status === 'pending') {
                    setPendingOrder(orderResponse.data);
                    setSelectedItems(orderResponse.data.items || []);
                } else {
                    setPendingOrder(null);
                    setSelectedItems([]);
                }
            } catch (error) {
                console.error('Failed to load data:', error);
                // Handle 404 (no pending orders) gracefully
                if (error.response && error.response.status === 404) {
                    setPendingOrder(null);
                    setSelectedItems([]);
                }
            }
        };

        if (isOpen && seat && seat.table) {
            fetchData();
            if (seat.chairIndex !== undefined) {
                setChairIndex(seat.chairIndex);
            }
            setChairCount(seat.table.capacity || 0);
        }
    }, [isOpen, seat]);

    if (!seat || !seat.table) {
        return null;
    }

    const handleAddItem = () => {
        const selectedItem = menuItems.find(item => item._id === menuItem);
        if (selectedItem) {
            setSelectedItems([
                ...selectedItems,
                {
                    menu_item_id: selectedItem._id,
                    name: selectedItem.name,
                    quantity,
                    price: selectedItem.price,
                    special_requests: specialRequests,
                    chair_index: chairIndex !== undefined ? chairIndex : null,
                }
            ]);
            // Reset the inputs
            setMenuItem('');
            setQuantity(1);
            setSpecialRequests('');
        }
    };

    const handleRemoveItem = (index) => {
        const updatedItems = selectedItems.filter((_, i) => i !== index);
        setSelectedItems(updatedItems);
    };

    const handleQuantityChange = (index, delta) => {
        const updatedItems = [...selectedItems];
        updatedItems[index].quantity = Math.max(1, updatedItems[index].quantity + delta);
        setSelectedItems(updatedItems);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const orderData = {
                table_id: seat.table._id,
                items: selectedItems,
                changed_by: user._id,
            };

            if (pendingOrder) {
                await api.put(`/orders/${pendingOrder.order_id}`, orderData);
            } else {
                await api.post('/orders', orderData);
            }
            console.log('Order submitted:', orderData);
            setSelectedItems([]);
            onClose();
        } catch (error) {
            console.error('Failed to submit order:', error);
        }
    };

    const title = seat && seat.table ?
        (seat.chairIndex !== undefined
            ? `Order for Table ${seat.table.number}, Chair ${seat.chairIndex + 1}`
            : `Order for Table ${seat.table.number}`)
        : 'Order';

    if (!seat || !seat.table) {
        return null;
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={title}>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Chair Selection</label>
                    <select
                        value={chairIndex}
                        onChange={(e) => setChairIndex(parseInt(e.target.value))}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                        {Array.from({ length: chairCount }, (_, i) => (
                            <option key={i} value={i}>
                                Chair {i + 1}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Menu Item</label>
                    <select
                        value={menuItem}
                        onChange={(e) => setMenuItem(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        required
                    >
                        <option value="">Select an item</option>
                        {menuItems.map(item => (
                            <option key={item._id} value={item._id}>
                                {item.name} - ₹{item.price}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-4 flex items-center">
                    <label className="block text-sm font-medium text-gray-700 mr-2">Quantity</label>
                    <button
                        type="button"
                        onClick={() => setQuantity(Math.max(1, quantity - 1))}
                        className="px-2 bg-gray-300 rounded"
                    >
                        -
                    </button>
                    <span className="mx-2">{quantity}</span>
                    <button
                        type="button"
                        onClick={() => setQuantity(quantity + 1)}
                        className="px-2 bg-gray-300 rounded"
                    >
                        +
                    </button>
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Special Requests</label>
                    <input
                        type="text"
                        value={specialRequests}
                        onChange={(e) => setSpecialRequests(e.target.value)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                </div>
                <button
                    type="button"
                    onClick={handleAddItem}
                    className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-200 mb-4"
                    disabled={!menuItem}
                >
                    Add Item
                </button>
                <div className="mb-4">
                    <h3 className="text-lg font-medium text-gray-700">
                        {pendingOrder ? "Pending Order" : "New Order"}
                    </h3>
                    {selectedItems.length > 0 ? (
                        <ul className="list-disc pl-5">
                            {selectedItems.map((item, index) => (
                                <li key={index} className="flex justify-between items-center mb-2">
                                    <div className="flex items-center space-x-2">
                                        <span>{item.name}</span>
                                        <button
                                            type="button"
                                            onClick={() => handleQuantityChange(index, -1)}
                                            className="px-2 bg-gray-300 rounded"
                                        >
                                            -
                                        </button>
                                        <span>{item.quantity}</span>
                                        <button
                                            type="button"
                                            onClick={() => handleQuantityChange(index, 1)}
                                            className="px-2 bg-gray-300 rounded"
                                        >
                                            +
                                        </button>
                                        {item.special_requests && (
                                            <span className="italic text-sm ml-2">
                                                ({item.special_requests})
                                            </span>
                                        )}
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveItem(index)}
                                        className="text-red-600 hover:text-red-800 transition duration-200"
                                    >
                                        Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No items added yet.</p>
                    )}
                </div>
                <div className="flex justify-end">
                    <button
                        type="submit"
                        className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
                        disabled={selectedItems.length === 0}
                    >
                        {pendingOrder ? "Update Order" : "Submit Order"}
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default OrderModal;
