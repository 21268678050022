// client/src/pages/Tables.js

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import TableVisualizer from '../components/TableVisualizer';
import Modal from '../components/Modal';
import OrderModal from '../components/OrderModal';
import { toast } from 'react-toastify';

const Tables = () => {
    const [tables, setTables] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentTable, setCurrentTable] = useState(null);
    const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
    const [selectedSeat, setSelectedSeat] = useState(null);

    useEffect(() => {
        const fetchTables = async () => {
            try {
                const response = await api.get('/tables');
                const fetchedTables = response.data || [];
                setTables(fetchedTables);

                // Fetch order statuses for each table
                const statuses = {};
                for (const table of fetchedTables) {
                    try {
                        const statusResponse = await api.get(`/tables/${table._id}/order-status`);
                        statuses[table._id] = statusResponse.data;
                    } catch (error) {
                        statuses[table._id] = { status: 'No Orders' };
                    }
                }
                setOrderStatuses(statuses);
            } catch (error) {
                toast.error('Failed to load tables');
            }
        };
        fetchTables();
    }, []);

    const handleAddTable = () => {
        setCurrentTable({ number: '', capacity: '', status: 'Available', shape: 'circle', x: 0, y: 0 });
        setIsModalOpen(true);
    };

    const handleEditTable = (table) => {
        setCurrentTable(table);
        setIsModalOpen(true);
    };

    const handleDeleteTable = async (id) => {
        try {
            await api.delete(`/tables/${id}`);
            setTables(tables.filter(table => table._id !== id));
            toast.success('Table deleted successfully');
        } catch (error) {
            toast.error('Failed to delete table');
        }
    };

    const handleSaveTable = async (table) => {
        if (!table.number || !table.capacity) {
            toast.error('Table Number and Capacity are required.');
            return;
        }
        try {
            if (table._id) {
                // Update existing table
                const response = await api.put(`/tables/${table._id}`, table);
                setTables(tables.map(t => (t._id === table._id ? response.data : t)));
                toast.success('Table updated successfully');
            } else {
                // Add new table
                const response = await api.post('/tables', table);
                setTables([...tables, response.data]);
                toast.success('Table added successfully');
            }
            setIsModalOpen(false);
        } catch (error) {
            toast.error('Failed to save table');
        }
    };

    const handleTableClick = (table) => {
        setSelectedSeat({ table, chairIndex: null });
        setIsOrderModalOpen(true);
    };

    const handleChairClick = (table, chairIndex) => {
        setSelectedSeat({ table, chairIndex });
        setIsOrderModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentTable(null); // Reset current table
    };

    const closeOrderModal = () => {
        setIsOrderModalOpen(false);
        setSelectedSeat(null); // Reset selected seat
    };

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-2xl font-bold mb-6">Manage Tables</h1>
            <button
                className="bg-blue-600 text-white py-2 px-4 rounded mb-4 hover:bg-blue-700 transition duration-200"
                onClick={handleAddTable}
            >
                Add Table
            </button>
            {tables.length > 0 ? (
                <TableVisualizer
                    tables={tables}
                    orderStatuses={orderStatuses}
                    onTableClick={handleTableClick}
                    onChairClick={handleChairClick}
                />
            ) : (
                <p>No tables available. Please add a table.</p>
            )}
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                title={currentTable && currentTable._id ? 'Edit Table' : 'Add Table'}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSaveTable(currentTable);
                    }}
                >
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Table Number
                        </label>
                        <input
                            type="text"
                            value={currentTable?.number || ''}
                            onChange={(e) =>
                                setCurrentTable({ ...currentTable, number: e.target.value })
                            }
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Capacity
                        </label>
                        <input
                            type="number"
                            value={currentTable?.capacity || ''}
                            onChange={(e) =>
                                setCurrentTable({ ...currentTable, capacity: e.target.value })
                            }
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <select
                            value={currentTable?.status || 'Available'}
                            onChange={(e) =>
                                setCurrentTable({ ...currentTable, status: e.target.value })
                            }
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option value="Available">Available</option>
                            <option value="Occupied">Occupied</option>
                            <option value="Reserved">Reserved</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Shape
                        </label>
                        <select
                            value={currentTable?.shape || 'circle'}
                            onChange={(e) =>
                                setCurrentTable({ ...currentTable, shape: e.target.value })
                            }
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option value="circle">Circle</option>
                            <option value="square">Square</option>
                            <option value="rectangle">Rectangle</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Position (X, Y)
                        </label>
                        <div className="flex space-x-2">
                            <input
                                type="number"
                                value={currentTable?.x || 0}
                                onChange={(e) =>
                                    setCurrentTable({ ...currentTable, x: parseInt(e.target.value) })
                                }
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                            <input
                                type="number"
                                value={currentTable?.y || 0}
                                onChange={(e) =>
                                    setCurrentTable({ ...currentTable, y: parseInt(e.target.value) })
                                }
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </Modal>
            <OrderModal
                isOpen={isOrderModalOpen}
                onClose={closeOrderModal}
                seat={selectedSeat}
            />
        </div>
    );
};

export default Tables;




