// client/src/components/TableVisualizer.js

import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer } from 'react-konva';
import OrderingTable from './OrderingTable';

const TableVisualizer = ({ tables, onTableClick, onChairClick, orderStatuses }) => {
    const [selectedTable, setSelectedTable] = useState(null);
    const [selectedChair, setSelectedChair] = useState(null);
    const [hoveredTable, setHoveredTable] = useState(null);
    const [stageSize, setStageSize] = useState({ width: window.innerWidth, height: 600 });
    const containerRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setStageSize({
                    width: containerRef.current.offsetWidth,
                    height: 600
                });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleTableClick = (table) => {
        if (selectedTable === table._id && selectedChair === null) {
            setSelectedTable(null);
        } else {
            setSelectedTable(table._id);
            setSelectedChair(null);
        }
        onTableClick(table);
    };

    const handleChairClick = (table, chairIndex) => {
        if (selectedTable === table._id && selectedChair === chairIndex) {
            setSelectedChair(null);
        } else {
            setSelectedTable(table._id);
            setSelectedChair(chairIndex);
        }
        onChairClick(table, chairIndex);
    };

    const handleCanvasClick = (e) => {
        if (e.target === e.target.getStage()) {
            setSelectedTable(null);
            setSelectedChair(null);
        }
    };

    const handleMouseEnter = (tableId) => {
        setHoveredTable(tableId);
    };

    const handleMouseLeave = () => {
        setHoveredTable(null);
    };

    const calculateTablePositions = () => {
        const padding = 40;
        const baseTableSize = 80;
        const maxColumns = Math.floor(stageSize.width / (baseTableSize * 3 + padding));
        const columns = Math.max(1, Math.min(maxColumns, tables.length));
        const rows = Math.ceil(tables.length / columns);

        let maxWidth = 0;
        let maxHeight = 0;

        const positionedTables = tables.map((table, index) => {
            const row = Math.floor(index / columns);
            const col = index % columns;

            const tableSizeMultiplier = Math.ceil(table.capacity / 4);
            const tableSize = baseTableSize + tableSizeMultiplier * 20;

            const xOffset = table.shape === 'rectangle' ? 20 : 0;
            const x = padding + col * (baseTableSize * 3 + padding) + tableSize / 2 + xOffset;
            const y = padding + row * (baseTableSize * 3 + padding) + tableSize / 2;

            maxWidth = Math.max(maxWidth, x + tableSize / 2 + padding);
            maxHeight = Math.max(maxHeight, y + tableSize / 2 + padding);

            return {
                ...table,
                type: table.shape || 'circle',
                x,
                y,
            };
        });

        return { positionedTables, totalWidth: maxWidth, totalHeight: maxHeight };
    };

    const { positionedTables, totalWidth, totalHeight } = calculateTablePositions();

    return (
        <div ref={containerRef} style={{ width: '100%', overflowX: 'auto' }}>
            <Stage
                width={Math.max(totalWidth, stageSize.width)}
                height={Math.max(totalHeight, stageSize.height)}
                onClick={handleCanvasClick}
            >
                <Layer>
                    {positionedTables.map((table) => (
                        <OrderingTable
                            key={table._id}
                            table={table}
                            onTableClick={handleTableClick}
                            onChairClick={handleChairClick}
                            isSelected={selectedTable === table._id}
                            selectedChairIndex={selectedTable === table._id ? selectedChair : null}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            isHovered={hoveredTable === table._id}
                            orderStatus={orderStatuses[table._id]} // Pass the order status
                        />
                    ))}
                </Layer>
            </Stage>
        </div>
    );
};

export default TableVisualizer;




