import React from 'react';

const FormInput = ({ name, label, value, onChange, error, required, type = 'text', textarea = false }) => {
    const InputComponent = textarea ? 'textarea' : 'input';
    return (
        <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <InputComponent
                type={type}
                name={name}
                value={value}
                onChange={(e) => onChange(name, e.target.value)}
                className={`mt-1 block w-full px-3 py-2 border ${error ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                required={required}
            />
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
    );
};

export default FormInput;