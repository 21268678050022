// client/src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Tables from './pages/Tables';
import MenuItems from './pages/MenuItems';
import Orders from './pages/Orders';
import Settings from './pages/Settings';
import ProtectedRoute from './components/ProtectedRoute';
import Layout from './components/Layout';
import { AuthProvider } from './context/authContext';
import { SettingsProvider } from './context/SettingsContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <AuthProvider>
            <SettingsProvider>
                <Router>
                    <ToastContainer />
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="*"
                            element={
                                <Layout>
                                    <Routes>
                                        <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
                                        <Route path="/tables" element={<ProtectedRoute component={Tables} />} />
                                        <Route path="/menu-items" element={<ProtectedRoute component={MenuItems} />} />
                                        <Route path="/orders" element={<ProtectedRoute component={Orders} />} />
                                        <Route path="/settings" element={<ProtectedRoute component={Settings} />} />
                                        <Route path="*" element={<Navigate to="/dashboard" />} />
                                    </Routes>
                                </Layout>
                            }
                        />
                    </Routes>
                </Router>
            </SettingsProvider>
        </AuthProvider>
    );
}

export default App;
