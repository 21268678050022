// client/src/components/CategoryForm.js

import React, { useState } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';

const CategoryForm = ({ onClose, refreshData }) => {
    const [name, setName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await api.post('/categories', { name });
            toast.success('Category added successfully');
            refreshData(); // Refresh the parent component's data
            onClose(); // Close the modal
        } catch (error) {
            toast.error('Failed to add category');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Category Name</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
            </div>
            <button
                type="submit"
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
            >
                Save
            </button>
        </form>
    );
};

export default CategoryForm;
