// client/src/components/ProtectedRoute.js

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AuthContext from '../context/authContext';
import Loader from './Loader'; // Import the Loader component

const ProtectedRoute = ({ component: Component }) => {
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return <Loader />; // Show the loader while the authentication state is being determined
    }

    return user ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
