// client/src/components/MenuItems.js

import React, {useState, useEffect, useContext} from 'react';
import api from '../services/api';
import SettingsContext from '../context/SettingsContext';
import { toast } from 'react-toastify';
import Table from './Table';
import Modal from './Modal';
import BulkUpload from './BulkUpload';
import MenuItemForm from './MenuItemForm';
import Loader from './Loader';

const MenuItems = () => {
    const [menuItems, setMenuItems] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [editingMenuItem, setEditingMenuItem] = useState(null);
    const { currencySymbol } = useContext(SettingsContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const menuResponse = await api.get('/menu-items');
                const categoryResponse = await api.get('/categories');
                setMenuItems(menuResponse.data);
                setCategories(categoryResponse.data);
            } catch (error) {
                toast.error('Failed to load menu items or categories');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleOpenModal = (menuItem = null) => {
        setEditingMenuItem(menuItem); // Set the item being edited or null for new items
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setEditingMenuItem(null); // Clear the item being edited when closing the modal
    };

    const refreshData = async () => {
        try {
            const menuResponse = await api.get('/menu-items');
            setMenuItems(menuResponse.data);
        } catch (error) {
            toast.error('Failed to refresh data');
        }
    };

    const handleDelete = async (menuItemId) => {
        if (window.confirm('Are you sure you want to delete this menu item?')) {
            try {
                await api.delete(`/menu-items/${menuItemId}`);
                toast.success('Menu item deleted successfully');
                refreshData();
            } catch (error) {
                toast.error('Failed to delete menu item');
            }
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <button
                className="bg-yellow-600 text-white py-2 px-4 rounded hover:bg-yellow-700 transition duration-200 mb-4"
                onClick={() => handleOpenModal()}
            >
                Add Menu Item
            </button>
            <Table columns={['Name', 'Price', 'Category', 'Dietary Category','Preparation time','Availability', 'Actions']} data={menuItems.map(item => ({
                name: item.name,
                price: `${currencySymbol}${item.price}`,
                category: item.category_id.name,
                dietary_category: (
                    <span className="flex justify-center items-center space-x-1">
                        {item.dietary_category === 'Vegetarian' && (
                            <div className="w-6 h-6 flex justify-center items-center border border-green-600 rounded">
                                <span className="text-green-600">🟢</span>
                            </div>
                        )}
                        {item.dietary_category === 'Non-Vegetarian' && (
                            <div className="w-6 h-6 flex justify-center items-center border border-red-600 rounded">
                                <span className="text-red-600">🔴</span>
                            </div>
                        )}
                        {item.dietary_category === 'Eggatarian' && (
                            <div className="w-6 h-6 flex justify-center items-center border border-yellow-600 rounded">
                                <span className="text-yellow-600">🟡</span>
                            </div>
                        )}
                        {item.dietary_category === 'Vegan' && (
                            <div className="w-6 h-6 flex justify-center items-center border border-green-600 rounded">
                                <span className="text-green-600">🌱</span>
                            </div>
                        )}
                    </span>
                ),
                preparation_time: (
                    <div className="text-center">
                        {item.preparation_time} minutes
                    </div>
                ),
                available: (
                    <span className="flex justify-center items-center">
                        {item.available ? (
                            <span className="text-green-600">✅</span>
                        ) : (
                            <span className="text-red-600">❌</span>
                        )}
                    </span>
                ),
                actions: (
                    <div className="flex space-x-2">
                        <button
                            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                            onClick={() => handleOpenModal(item)} // Pass the item being edited
                        >
                            Edit
                        </button>
                        <button
                            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                            onClick={() => handleDelete(item._id)}
                        >
                            Delete
                        </button>
                    </div>
                )
            }))} />
            <Modal isOpen={isModalOpen} onClose={closeModal} title={editingMenuItem ? "Edit Menu Item" : "Add Menu Item"}>
                <MenuItemForm
                    onClose={closeModal}
                    categories={categories}
                    refreshData={refreshData}
                    initialData={editingMenuItem} // Pass the initial data to the form
                />
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">Bulk Upload Menu Items</h3>
                <BulkUpload uploadEndpoint="/menu-items/bulk-upload" />
            </Modal>
        </div>
    );
};

export default MenuItems;

