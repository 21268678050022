// client/src/components/Layout.js

import React, { useContext, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import AuthContext from '../context/authContext';
import SettingsContext from '../context/SettingsContext';

const Layout = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { user, logout } = useContext(AuthContext);
    const { appName, primaryColor, secondaryColor } = useContext(SettingsContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <div className="min-h-screen flex flex-col" style={{ backgroundColor: primaryColor }}>
            <nav className="bg-blue-600 text-white shadow-lg" style={{ backgroundColor: secondaryColor }}>
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <div className="text-lg font-bold">
                        <Link to="/">{appName}</Link>
                    </div>
                    <div className="hidden md:flex space-x-6 items-center">
                        <NavLink
                            to="/dashboard"
                            className={({ isActive }) =>
                                isActive ? 'border-b-2 border-white pb-1' : 'hover:border-b-2 hover:border-white pb-1 transition duration-200 ease-in-out'
                            }
                        >
                            Dashboard
                        </NavLink>
                        <NavLink
                            to="/tables"
                            className={({ isActive }) =>
                                isActive ? 'border-b-2 border-white pb-1' : 'hover:border-b-2 hover:border-white pb-1 transition duration-200 ease-in-out'
                            }
                        >
                            Tables
                        </NavLink>
                        <NavLink
                            to="/menu-items"
                            className={({ isActive }) =>
                                isActive ? 'border-b-2 border-white pb-1' : 'hover:border-b-2 hover:border-white pb-1 transition duration-200 ease-in-out'
                            }
                        >
                            Menu Items
                        </NavLink>
                        <NavLink
                            to="/orders"
                            className={({ isActive }) =>
                                isActive ? 'border-b-2 border-white pb-1' : 'hover:border-b-2 hover:border-white pb-1 transition duration-200 ease-in-out'
                            }
                        >
                            Orders
                        </NavLink>
                        <NavLink
                            to="/settings"
                            className={({ isActive }) =>
                                isActive ? 'border-b-2 border-white pb-1' : 'hover:border-b-2 hover:border-white pb-1 transition duration-200 ease-in-out'
                            }
                        >
                            Settings
                        </NavLink>
                        {user && (
                            <button
                                onClick={handleLogout}
                                className="ml-4 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded transition duration-200 ease-in-out"
                            >
                                Logout
                            </button>
                        )}
                    </div>
                    <div className="md:hidden">
                        <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="focus:outline-none">
                            <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16m-7 6h7"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden">
                        <NavLink
                            to="/dashboard"
                            className="block px-4 py-2 text-white hover:bg-blue-700"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Dashboard
                        </NavLink>
                        <NavLink
                            to="/tables"
                            className="block px-4 py-2 text-white hover:bg-blue-700"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Tables
                        </NavLink>
                        <NavLink
                            to="/menu-items"
                            className="block px-4 py-2 text-white hover:bg-blue-700"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Menu Items
                        </NavLink>
                        <NavLink
                            to="/orders"
                            className="block px-4 py-2 text-white hover:bg-blue-700"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Orders
                        </NavLink>
                        <NavLink
                            to="/settings"
                            className="block px-4 py-2 text-white hover:bg-blue-700"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            Settings
                        </NavLink>
                        {user && (
                            <button
                                onClick={handleLogout}
                                className="block w-full text-left px-4 py-2 text-white bg-red-500 hover:bg-red-600 transition duration-200 ease-in-out"
                            >
                                Logout
                            </button>
                        )}
                    </div>
                )}
            </nav>

            <main className="flex-grow container mx-auto px-4 py-6">
                {children}
            </main>

            <footer className="bg-blue-600 text-white text-center py-4" style={{ backgroundColor: secondaryColor }}>
                &copy; 2024 {appName}. All rights reserved.
            </footer>
        </div>
    );
};

export default Layout;
