// client/src/components/Suppliers.js

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
import Table from './Table';
import Modal from './Modal';
import SupplierForm from './SupplierForm';
import Loader from './Loader';

const Suppliers = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentSupplier, setCurrentSupplier] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await api.get('/suppliers');
                setSuppliers(response.data);
            } catch (error) {
                toast.error('Failed to load suppliers');
            } finally {
                setLoading(false);
            }
        };

        fetchSuppliers();
    }, []);

    const handleOpenModal = (supplier = null) => {
        setCurrentSupplier(supplier);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentSupplier(null);
    };

    const refreshData = async () => {
        try {
            const response = await api.get('/suppliers');
            setSuppliers(response.data);
        } catch (error) {
            toast.error('Failed to refresh data');
        }
    };

    const handleDelete = async (supplierId) => {
        if (window.confirm('Are you sure you want to delete this supplier?')) {
            try {
                await api.delete(`/suppliers/${supplierId}`);
                toast.success('Supplier deleted successfully.');
                refreshData();
            } catch (error) {
                toast.error('Failed to delete supplier.');
            }
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <button
                className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-200 mb-4"
                onClick={() => handleOpenModal()}
            >
                Add Supplier
            </button>
            <Table
                columns={['Name', 'Contact Name', 'Phone', 'Email', 'Actions']}
                data={suppliers.map(supplier => ({
                    name: supplier.name,
                    contact_name: supplier.contact_name,
                    phone: supplier.phone,
                    email: supplier.email,
                    actions: (
                        <div className="flex space-x-2">
                            <button
                                className="bg-blue-600 text-white py-1 px-2 rounded hover:bg-blue-700 transition duration-200"
                                onClick={() => handleOpenModal(supplier)}
                            >
                                Edit
                            </button>
                            <button
                                className="bg-red-600 text-white py-1 px-2 rounded hover:bg-red-700 transition duration-200"
                                onClick={() => handleDelete(supplier._id)}
                            >
                                Delete
                            </button>
                        </div>
                    ),
                }))}
            />
            <Modal isOpen={isModalOpen} onClose={closeModal} title={currentSupplier ? 'Edit Supplier' : 'Add Supplier'}>
                <SupplierForm
                    supplier={currentSupplier}
                    onSave={() => {
                        closeModal();
                        refreshData();
                    }}
                    onCancel={closeModal}
                />
            </Modal>
        </div>
    );
};

export default Suppliers;
