// client/src/pages/MenuItems.js

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import Table from '../components/Table';
import Modal from '../components/Modal';
import { toast } from 'react-toastify';

const MenuItems = () => {
    const [menuItems, setMenuItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const currencySymbol = '₹';

    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const response = await api.get('/menu-items');
                setMenuItems(response.data);
            } catch (error) {
                toast.error('Failed to load menu items');
            }
        };

        fetchMenuItems();
    }, []);

    const handleRowClick = (item) => {
        setCurrentItem(item);
        setIsModalOpen(true);
    };

    const columns = ['Name', 'Description', 'Price', 'Category', 'Dietary Category', 'Availability', 'Preparation Time'];

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-2xl font-bold mb-6">Menu Items</h1>
            {menuItems.length > 0 ? (
                <Table
                    columns={columns}
                    data={menuItems.map(item => ({
                        name: item.name,
                        description: item.description,
                        price: `${currencySymbol}${item.price}`,
                        category: item.category_id.name,
                        dietaryCategory: item.dietary_category,
                        availability: item.available ? 'Available' : 'Not Available',
                        preparationTime: `${item.preparation_time} mins`,
                        onClick: () => handleRowClick(item) // Attach click handler
                    }))}
                    rowClickable={true} // Add rowClickable prop to enable row click behavior
                />
            ) : (
                <p>No menu items available.</p>
            )}
            {currentItem && (
                <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    title="Menu Item Details"
                >
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Name</h2>
                        <p>{currentItem.name}</p>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Description</h2>
                        <p>{currentItem.description}</p>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Price</h2>
                        <p>{currencySymbol}{currentItem.price}</p>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Category</h2>
                        <p>{currentItem.category_id.name}</p>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Ingredients</h2>
                        <ul>
                            {currentItem.ingredients.map((ingredient, index) => (
                                <li key={index}>{ingredient.ingredient_id.name} - {ingredient.unit}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Nutrition Info</h2>
                        <ul>
                            {Object.entries(currentItem.nutrition_info).map(([key, value], index) => (
                                <li key={index}>{key}: {value}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Dietary Category</h2>
                        <p>{currentItem.dietary_category}</p>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Availability</h2>
                        <p>{currentItem.available ? 'Available' : 'Not Available'}</p>
                    </div>
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Preparation Time</h2>
                        <p>{currentItem.preparation_time} mins</p>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default MenuItems;



