// client/src/pages/Orders.js

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import Table from '../components/Table';

const Orders = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            const response = await api.get('/orders');
            setOrders(response.data);
        };
        fetchOrders();
    }, []);

    const columns = ['Table Number', 'Total Amount', 'Status'];

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-2xl font-bold mb-6">Manage Orders</h1>
            <Table columns={columns} data={orders.map(order => ({
                table_number: order.table_id.number,
                total_amount: `$${order.total_amount}`,
                status: order.status,
            }))} />
        </div>
    );
};

export default Orders;
