// client/src/components/Ingredients.js

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
import Table from './Table';
import Modal from './Modal';
import BulkUpload from './BulkUpload';
import IngredientForm from './IngredientForm';
import Loader from './Loader';

const Ingredients = () => {
    const [ingredients, setIngredients] = useState([]);
    const [selectedIngredient, setSelectedIngredient] = useState(null); // For editing
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchIngredients = async () => {
            try {
                const response = await api.get('/ingredients');
                setIngredients(response.data);
            } catch (error) {
                toast.error('Failed to load ingredients');
            } finally {
                setLoading(false);
            }
        };

        fetchIngredients();
    }, []);

    const handleOpenModal = (ingredient = null) => {
        setSelectedIngredient(ingredient);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedIngredient(null);
    };

    const refreshData = async () => {
        try {
            const response = await api.get('/ingredients');
            setIngredients(response.data);
        } catch (error) {
            toast.error('Failed to refresh data');
        }
    };

    const handleDelete = async (ingredientId) => {
        if (window.confirm('Are you sure you want to delete this ingredient?')) {
            try {
                await api.delete(`/ingredients/${ingredientId}`);
                toast.success('Ingredient deleted successfully');
                refreshData();
            } catch (error) {
                toast.error('Failed to delete ingredient');
            }
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <button
                className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition duration-200 mb-4"
                onClick={() => handleOpenModal()}
            >
                Add Ingredient
            </button>
            <Table columns={['Name', 'Nutrition Info', 'Origin', 'Actions']} data={ingredients.map(ingredient => ({
                name: ingredient.name.en,  // Extracting the English name
                nutrition_info: ingredient.nutrition_info.en,  // Extracting the English nutrition info
                origin: ingredient.origin,
                actions: (
                    <div className="flex space-x-2">
                        <button
                            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                            onClick={() => handleOpenModal(ingredient)}
                        >
                            Edit
                        </button>
                        <button
                            className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                            onClick={() => handleDelete(ingredient._id)}
                        >
                            Delete
                        </button>
                    </div>
                )
            }))} />
            <Modal isOpen={isModalOpen} onClose={closeModal} title={selectedIngredient ? "Edit Ingredient" : "Add Ingredient"}>
                <IngredientForm
                    ingredient={selectedIngredient}  // Pass the selected ingredient for editing
                    onClose={closeModal}
                    refreshData={refreshData}
                />
                {!selectedIngredient && (
                    <>
                        <hr className="my-4" />
                        <h3 className="text-lg font-semibold mb-4">Bulk Upload Ingredients</h3>
                        <BulkUpload uploadEndpoint="/ingredients/bulk-upload" />
                    </>
                )}
            </Modal>
        </div>
    );
};

export default Ingredients;

