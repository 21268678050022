// client/src/components/Modal.js

import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const Modal = ({ isOpen, onClose, title, children, className = '' }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black bg-opacity-50"
                onClick={onClose}
                style={{ zIndex: 50 }}
            ></motion.div>
            <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ type: 'spring', stiffness: 300, damping: 20 }}
                className={`relative bg-white rounded-lg shadow-lg max-w-lg w-full p-6 mx-4 sm:mx-0 ${className}`}
                style={{ zIndex: 100, maxHeight: '90vh' }}  // Restrict modal height to viewport
            >
                <div className="flex justify-between items-center border-b pb-3">
                    <h3 className="text-lg font-semibold">{title}</h3>
                    <button
                        onClick={onClose}
                        className="text-gray-600 hover:text-gray-900 focus:outline-none"
                    >
                        &times;
                    </button>
                </div>
                <div className="mt-4 overflow-y-auto custom-scrollbar" style={{ maxHeight: '70vh' }}>
                    {children}
                </div>
            </motion.div>
        </div>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default Modal;



