// client/src/components/Card.js

import React from 'react';

const Card = ({ title, value, icon, backgroundColor, textColor }) => {
    return (
        <div className={`p-4 rounded-lg shadow-lg ${backgroundColor || 'bg-white'}`}>
            <div className="flex items-center">
                {icon && <div className="text-4xl mr-4" style={{ color: textColor || '#000' }}>{icon}</div>}
                <div>
                    <h2 className={`text-xl font-semibold mb-2 ${textColor || 'text-gray-700'}`}>{title}</h2>
                    <p className={`text-2xl font-bold ${textColor || 'text-gray-900'}`}>{value}</p>
                </div>
            </div>
        </div>
    );
};

export default Card;
