// client/src/components/Categories.js

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
import Table from './Table';
import Modal from './Modal';
import BulkUpload from './BulkUpload';
import CategoryForm from './CategoryForm';
import Loader from './Loader';

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await api.get('/categories');
                setCategories(response.data);
            } catch (error) {
                toast.error('Failed to load categories');
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const refreshData = async () => {
        try {
            const response = await api.get('/categories');
            setCategories(response.data);
        } catch (error) {
            toast.error('Failed to refresh data');
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <button
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200 mb-4"
                onClick={handleOpenModal}
            >
                Add Category
            </button>
            <Table columns={['Name']} data={categories.map(category => ({
                name: category.name,
            }))} />
            <Modal isOpen={isModalOpen} onClose={closeModal} title="Add Category">
                <CategoryForm onClose={closeModal} refreshData={refreshData} />
                <hr className="my-4" />
                <h3 className="text-lg font-semibold mb-4">Bulk Upload Categories</h3>
                <BulkUpload uploadEndpoint="/categories/bulk-upload" />
            </Modal>
        </div>
    );
};

export default Categories;
