// client/src/components/Stock.js

import React, { useState, useEffect, useContext } from 'react';
import api from '../services/api';
import SettingsContext from '../context/SettingsContext';
import { toast } from 'react-toastify';
import Table from './Table';
import Loader from './Loader';
import Modal from './Modal';
import StockForm from './StockForm';
import { format } from 'date-fns';

const Stock = () => {
    const [stockItems, setStockItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingStock, setEditingStock] = useState(null);
    const { currencySymbol } = useContext(SettingsContext);

    useEffect(() => {
        fetchStock();
    }, []);

    const fetchStock = async () => {
        setLoading(true);
        try {
            const response = await api.get('/stocks');
            setStockItems(response.data);
        } catch (error) {
            console.error('Error fetching stock:', error);
            toast.error('Failed to load stock data');
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModal = (stock = null) => {
        setEditingStock(stock);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setEditingStock(null);
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this stock item?')) {
            try {
                await api.delete(`/stocks/${id}`);
                toast.success('Stock item deleted successfully');
                fetchStock();
            } catch (error) {
                console.error('Error deleting stock:', error);
                toast.error('Failed to delete stock item');
            }
        }
    };

    const calculateTotalPrice = (quantity, pricePerUnit) => {
        return (quantity * pricePerUnit).toFixed(2);
    };

    if (loading) {
        return <Loader />;
    }

    const tableData = stockItems.map(stock => ({
        ingredient: stock.ingredient_id?.name?.en || stock.ingredient_id?.name || 'N/A',
        quantity: stock.quantity_in_stock || 'N/A',
        unit: stock.unit?.abbreviation || stock.unit?.name || 'N/A',
        pricePerUnit: `${currencySymbol}${stock.price_per_unit.toFixed(2)}`,
        totalPrice: `${currencySymbol}${calculateTotalPrice(stock.quantity_in_stock, stock.price_per_unit)}`,
        expiryDate: stock.expiry_date ? format(new Date(stock.expiry_date), 'yyyy-MM-dd') : 'N/A',
        receivedDate: stock.received_date ? format(new Date(stock.received_date), 'yyyy-MM-dd') : 'N/A',
        actions: (
            <div className="flex space-x-2">
                <button
                    onClick={() => handleOpenModal(stock)}
                    className="bg-yellow-500 text-white p-1 rounded hover:bg-yellow-600"
                >
                    Edit
                </button>
                <button
                    onClick={() => handleDelete(stock._id)}
                    className="bg-red-500 text-white p-1 rounded hover:bg-red-600"
                >
                    Delete
                </button>
            </div>
        ),
    }));

    return (
        <div>
            <h2 className="text-xl font-semibold mb-4">Stock Management</h2>
            <div className="flex space-x-4 mb-4">
                <button
                    className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-200"
                    onClick={() => handleOpenModal()}
                >
                    Add Stock
                </button>
            </div>
            {stockItems.length > 0 ? (
                <Table
                    columns={['Ingredient', 'Quantity', 'Unit', 'Price per Unit', 'Total Price', 'Expiry Date', 'Received Date', 'Actions']}
                    data={tableData}
                />
            ) : (
                <p>No stock available. Please start by adding some stocks.</p>
            )}
            <Modal isOpen={isModalOpen} onClose={closeModal} title={editingStock ? "Edit Stock" : "Add Stock"}>
                <StockForm onClose={closeModal} refreshData={fetchStock} initialData={editingStock} />
            </Modal>
        </div>
    );
};

export default Stock;